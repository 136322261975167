import React from 'react';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core';
import Layout from '../components/layout';
import PageContainer from '../components/Containers/PageContainer';
import PageSection from '../components/Cards/PageSection';
import Header from '../components/Header';
import { Grid } from '@material-ui/core';
import { shape } from 'prop-types';
import SIZES from '../constants/sizes';
import COLORS from '../constants/colors';
import FONTS from '../constants/fonts';
import { Link } from 'gatsby-plugin-intl';

const useStyles = makeStyles(theme => ({
  title: {
    color: COLORS.DARK_BLUE,
    paddingBottom: 80,
    paddingLeft: SIZES.PADDING.LEFT_PADDING.DEFAULT,
    paddingRight: SIZES.PADDING.RIGHT_PADDING.DEFAULT,

    [theme.breakpoints.down('md')]: {
      paddingBottom: 40,
      paddingLeft: SIZES.PADDING.LEFT_PADDING.MD,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.MD,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.XS,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.XS,
    },
  },
  backLink: {
    display: 'inline-block',
    marginBottom: 25,
    color: COLORS.BLUE,
    textDecoration: 'none',
    fontSize: FONTS.SIZE.XS,
    fontWeight: FONTS.WEIGHT.BOLD,
    paddingLeft: SIZES.PADDING.LEFT_PADDING.DEFAULT + 55,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.DEFAULT + 55,
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.MD + 55,
    },

    [theme.breakpoints.down('xs')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.XS,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.XS,
    },
  },
}));

const Solution = ({
  data: {
    contentfulScanshipSolution: {
      solutionName,
      bodyTextHeader,
      bodyText: { bodyText },
      image: { fluid },
    },
  },
}) => {
  const classes = useStyles();

  return (
    <Layout>
      <PageContainer className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Header
              sub
              textTransform="lowerCase"
              className={classes.title}
            >
              {solutionName}
            </Header>
          </Grid>
          <Grid item xs={12}>
            <Link
              to="/solutions"
              className={classes.backLink}
            >
              Back to solutions
            </Link>
          </Grid>
          <Grid item xs={12}>
            <PageSection
              imgFluid={fluid}
              title={bodyTextHeader}
              description={bodyText}
              paddingTop={false}
              paddingBottom={false}
              reversed
            />
          </Grid>
        </Grid>
      </PageContainer>
    </Layout>
  );
};

Solution.propTypes = {
  data: shape(),
};

export default Solution;

export const query = graphql`
  query SolutionSlug($slug: String!) {
    contentfulScanshipSolution(slug: { eq: $slug }) {
      solutionName
      bodyTextHeader
      bodyText {
        bodyText
      }
      image {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
  }
`;
